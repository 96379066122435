import AddBlog from "../components/AdminPanel/AddBlog/AddBlog";
import AddAbout from "../components/AdminPanel/AddAbout/AddAbout";
import AddMedia from "../components/AdminPanel/AddMedia/AddMedia";
import Sneharth from "../components/Sneharth/Sneharth";
import HomePage from "../HomePage/HomePage";
import AdminLayout from "../layout/AdminLayout";
import AdminSneharth from "../components/AdminPanel/AdminSneharth/AdminSneharth";
import SnearthModal from "../components/AdminPanel/SnearthModal/SnearthModal";
import AddHonor from "../components/AdminPanel/AddHonor";
import AddReq from "../components/AdminPanel/AddReq/AddReq";
import AdminSnearthModal from "../components/AdminPanel/SnearthModal/AdminSnearthModal";
import Error from "../components/Error/Error";
const { createBrowserRouter } = require("react-router-dom");
const { default: Main } = require("../layout/Main");

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
    ],
  },
  {
    path: "/snearth",
    element: <Sneharth />,
  },
  {
    path: "*",
    element: <Error />,
  },
  {
    path: "/AdminSnehaP",
    element: <AdminLayout />,
    children: [
      {
        path: "/AdminSnehaP",
        element: <AddAbout />,
      },

      {
        path: "/AdminSnehaP/add-blog",
        element: <AddBlog />,
      },
      {
        path: "/AdminSnehaP/add-media",
        element: <AddMedia />,
      },
      {
        path: "/AdminSnehaP/snearth",
        element: <AdminSneharth />,
      },
      {
        path: "/AdminSnehaP/add-honor",
        element: <AddHonor />,
      },
      {
        path: "/AdminSnehaP/add-requirment",
        element: <AddReq />,
      },
    ],
  },

  {
    path: "/snearth/gallery/:id",
    element: <SnearthModal></SnearthModal>,
    loader: ({ params }) =>
      fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/gallery/${params.id}`
      ),
  },
  {
    path: "/AdminSnehaP/snearth/gallery/:id",
    element: <AdminSnearthModal></AdminSnearthModal>,
    loader: ({ params }) =>
      fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/gallery/${params.id}`
      ),
  },
]);
