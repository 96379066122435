import React from 'react';
import { Link } from 'react-router-dom';
import {FaHome} from 'react-icons/fa'

const SnearthNav = () => {
    return (
        <div class="bg-black ">
        <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-14  lg:px-12 xl:px-0 lg:py-[20px]">
          <div class="relative flex items-center justify-between ">
          <a href='/'>
              <span class="ml-2 text-xl md:text-[20px] font-normal tracking-wider text-white  uppercase">
                Snearth
              </span>
              </a>
            
            
            
                  <div>
                <Link to='/' className="font-medium  tracking-wide text-white hidden md:block"><FaHome className='text-[29px]'/></Link>
                <Link to='/' className="font-medium tracking-wide text-white md:hidden"><FaHome className='text-[20px] '/></Link>
              </div>
              </div>
              </div>
        </div>
    );
};

export default SnearthNav;