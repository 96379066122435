import { createContext, useState } from "react";

export const UserContext = createContext() 

const Context = ({children})=>{
    const [index, setIndex] =useState(0)
    const mediaInfo ={
        index,
        setIndex
    }


    return (
        <UserContext.Provider value={mediaInfo}>
            {children}
        </UserContext.Provider>
    )
}
export default Context;