import React from 'react';
import hero from '../../assets/hero.png'
const HeroScetions = () => {
    return (
        
              <div className="back-ground bg-cover bg-center w-full h-[455px]  md:h-[500px] lg:h-[580px] xl:h-[700px]  relative" style={{ backgroundImage: `url(${hero})` }}>
                    
                    <h1 className='text-white text-sm md:text-[32px] capitalize tracking-wider
                    font-normal w-full md:w-full text-center absolute  bottom-14 md:bottom-7 lg:bottom-10 xl:bottom-14'>Discovering Divinity Through Lens
                    </h1>
                </div>
            
       
    );
};

export default HeroScetions;