import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { FaPencilAlt } from "react-icons/fa";
import AdminBlog from "./AdminBlog";

const AddBlog = () => {
  const imgBbKey = "f375a7d7cbf6443df6506145faff4414";

  console.log(imgBbKey);
  // display image

  const [previewImage, setPreviewImage] = useState();
  const check = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewImage(e.target.files[0]);
    }
  };

  const handleAddTask = (event) => {
    event.preventDefault();
    const form = event.target;
    const title = form.title.value;
    const date = form.date.value;
    const platform = form.platform.value;
    const description = form.description.value;
    const link = form.link.value;
    const img = form.img.files[0];

    const formData = new FormData();
    formData.append("image", img);
    const url = `https://api.imgbb.com/1/upload?key=${imgBbKey}`;
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((imgData) => {
        if (imgData.success) {
          const addBlog = {
            title,
            date,
            platform,
            description,
            img: imgData.data.url,
            link,
          };
          console.log(addBlog);

          fetch(
            "https://sneha-poddar-server-70f8de076d6f.herokuapp.com/blogs",
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(addBlog),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.acknowledged) {
                toast.success(`You have added a blog successfully`);
                setPreviewImage(null);
                form.reset("");
              } else {
                alert("Error");
              }
            })
            .catch((err) => alert(err));
        }
      });
  };
  return (
    <div className="bg-gray-100">
      <div className="flex justify-center items-center py-16 min-h-screen px-3  ">
        <div className="flex flex-col items-center space-y-1 shadow-lg  w-[800px] px-3 md:p-10 md:space-y-3 border-2 rounded-md bg-white ">
          <h1 className="text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2 mt-3">
            Add a Blog{" "}
            <FaPencilAlt className="inline-block text-teal-accent-400 mb-2" />
          </h1>

          <div onChange={previewImage} className="hover:scale-110 duration-300">
            {previewImage ? (
              <img
                className="h-60 w-60"
                src={URL.createObjectURL(previewImage)}
                alt=""
              />
            ) : (
              <img
                className="h-60 w-60 mb-5"
                src="https://img.freepik.com/free-vector/paper-pencil-cartoon-icon-illustration-education-object-icon-concept-isolated-flat-cartoon-style_138676-2137.jpg?size=338&ext=jpg&ga=GA1.1.821203553.1657130385&semt=sph"
                alt=""
              />
            )}
          </div>

          <form
            onSubmit={handleAddTask}
            className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700  w-full"
          >
            <input
              className=" px-3 py-2 rounded-lg shadow-sm border  border-none w-full 
                            focus:outline-none  bg-white text-gray-900"
              type="file"
              name="img"
              id=""
              onChange={check}
              accept="image/*"
              required
            />

            <textarea
              name="title"
              placeholder="Title of Blog"
              className="w-full h-20 pt-3 px-2 text-lg md:text-xl border bg-white border-gray-200 rounded-md
                         outline-none text-gray-900 "
              required
            ></textarea>

            <input
              name="date"
              placeholder="23-03-2023"
              className="w-full h-16 px-2 
                        text-lg md:text-xl border border-gray-200 bg-white rounded-md outline-none text-gray-900"
              required
            />

            <select
              name="platform"
              className="w-full h-16 px-2 text-lg md:text-xl text-gray-900 
                        border border-gray-200 rounded-md outline-none bg-white "
              required
            >
              <option>Select Platform</option>
              <option value="medium">Medium</option>
              <option value="substack">Substack</option>
              <option value="ppt">PPT</option>
            </select>

            <textarea
              name="description"
              placeholder="Short Description(min 160 character)"
              maxLength={160}
              className="w-full 
                        h-20 pt-3 px-2 text-lg md:text-xl border border-gray-200 rounded-md outline-none
                        text-gray-900 bg-white"
              required
            ></textarea>

            <input
              name="link"
              placeholder="Link of Blog"
              className="w-full h-16 px-2 
                        text-lg md:text-xl border border-gray-200 rounded-md outline-none text-gray-900 bg-white"
              required
            />

            <button className="bg-[#fff] w-full text-black  border border-gray-300 px-6 py-3 md:py-3 text-2xl font-semibold  shadow-gray-200 shadow-md rounded-md ">
              Save now
            </button>
          </form>
        </div>
      </div>
      <div className="flex items-center justify-center pb-14"></div>
      <AdminBlog />
    </div>
  );
};

export default AddBlog;
