import React, { useEffect, useState } from "react";
import AllBlogModal from "../AllBlogModal/AllBlogModal";
import BlogCard from "./BlogCard";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/all-blog")
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
      });
  }, [blogs]);

  return (
    <div id="blog" className="px-5 md:px-14 xl:px-20  pb-7 bg-[#fff]">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center pt-2 pb-6">
        <div className="flex flex-col lg:justify-start space-y-2  py-1">
          <p className="text-2xl lg:text-[28px] font-bold text-gray-800">
            Blog
          </p>
          <p className="text-xs md:text-[16px] text-[#21243D]">
            Presentations, Publications, Videos & Podcasts
          </p>
        </div>

        <div>
          <label
            htmlFor="my-modal-3"
            className=" text-[20px] font-bold text-black"
          >
            View all
          </label>
          <AllBlogModal />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-x-[83px] lg:gap-x-[68px] xl:gap-x-40 ">
        {blogs.map((blog) => (
          <div className="bg-[#fff] border  w-full lg:w-[250px] xl:w-[330px] rounded-md shadow-md">
            <BlogCard blog={blog} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
