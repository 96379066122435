import React from 'react';
import AdminNavBar from '../components/AdminPanel/AdminNavBar/AdminNavBar';
import { Outlet } from 'react-router-dom';

const AdminLayout = () => {
    return (
        <div>
            <AdminNavBar/>
            <Outlet/>
        </div>
    );
};

export default AdminLayout;