import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { GoDeviceCamera } from "react-icons/go";

const AddAboutImage = () => {
  const [previewImage, setPreviewImage] = useState();
  const imgBbKey = "f375a7d7cbf6443df6506145faff4414";

  const check = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewImage(e.target.files[0]);
    }
  };

  //update image
  const handlUpdateImage = (event) => {
    event.preventDefault();

    const form = event.target;
    const img = form.img.files[0];

    const formData = new FormData();
    formData.append("image", img);
    const url = `https://api.imgbb.com/1/upload?key=${imgBbKey}`;
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((imgData) => {
        if (imgData.success) {
          const mediaInfo = {
            image: imgData.data.url,
          };

          fetch(
            `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/about-image/642fa412d6e0058d40b86b82`,
            {
              method: "PUT",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(mediaInfo),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.acknowledged) {
                toast.success(`You have updated your image successfully`);
                setPreviewImage(null);
                form.reset("");
              } else {
                alert("Error");
              }
            })
            .catch((err) => alert(err));
        }
      });
  };
  return (
    <div className="bg-white mt-5">
      <div className="flex flex-col items-center space-y-1 shadow-lg w-[330px] md:w-[800px] px-3 md:p-10 md:space-y-3 border-2 rounded-md bg-white">
        <h1 className="text-sm md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2 pt-3">
          Replace a new image{" "}
          <GoDeviceCamera className="inline-block text-teal-accent-400" />
        </h1>

        <div onChange={previewImage} className="hover:scale-110 duration-300">
          {previewImage ? (
            <img
              className="h-72 w-60"
              src={URL.createObjectURL(previewImage)}
              alt=""
            />
          ) : (
            <img
              className="h-40 w-40 md:h-72 md:w-60 mb-5"
              src="https://t3.ftcdn.net/jpg/02/71/98/34/240_F_271983494_a0cGPE7eV30hIWQdR4YZX6or48PAb9V1.jpg"
              alt=""
            />
          )}
        </div>

        <form
          onSubmit={handlUpdateImage}
          className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg  hover:shadow-lg w-full"
        >
          <input
            className=" px-3 py-2 rounded-lg shadow-sm border  border-none w-full focus:outline-none  bg-white "
            type="file"
            name="img"
            id=""
            onChange={check}
            accept="image/*"
            required
          />

          <div className="flex items-center space-x-2"></div>

          <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-200 shadow-md rounded-md ">
            Update now
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddAboutImage;
