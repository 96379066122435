import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const AdminSneharth = () => {
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [medias, setMedias] = useState([]);
  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/all-media")
      .then((res) => res.json())
      .then((data) => {
        setMedias(data);
      });
  }, [medias]);

  // delete messege https://sneha-poddar-server-70f8de076d6f.herokuapp.com/all-media
  const handleDelete = (media) => {
    const agree = window.confirm("Are you ready to delete ?");
    if (agree) {
      fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/medias/${media._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This image has been deleted successfully !");
            navigate("/AdminSnehaP/snearth");
          }
        });
    }
  };
  // videos
  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/video")
      .then((response) => response.json())
      .then((data) => setVideos(data))
      .catch((error) => console.error(error));
  }, []);
  // delete video https://sneha-poddar-server-70f8de076d6f.herokuapp.com/all-media
  const handleVideoDelete = (video) => {
    const agree = window.confirm("Are you ready to delete ?");
    if (agree) {
      fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/delete-video/${video._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This video has been deleted successfully !");
            navigate("/AdminSnehaP/snearth");
          }
        });
    }
  };

  return (
    <div className="bg-white">
      <div className="bg-white">
        {medias.length === 0 ? (
          <div className="flex justify-center items-center space-x-4 pt-40 mb-5">
            <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-black"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 px-5 md:px-10 py-5 md:py-10 max-h-[100vh] overflow-y-auto bg-white scrollbar-hide">
            {medias.map((media) => (
              <article
                htmlFor="my-modal-3"
                class="rounded-lg border border-gray-300 p-4 shadow-xl  transition hover:shadow-lg sm:p-6 "
              >
                <div className="flex justify-between items-center">
                  <span
                    onClick={() => handleDelete(media)}
                    class="my-4 rounded cursor-pointer bg-red-500 p-2 text-white"
                  >
                    <FaTrash className="text-xl md:text-2xl" />
                  </span>
                </div>

                <div className="flex flex-col">
                  <Link to={`/AdminSnehaP/snearth/gallery/${media._id}`}>
                    <img
                      className="h-[400px] w-full"
                      src={media?.image}
                      alt=""
                    />
                  </Link>
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
      {/* video */}
      <div className="bg-white">
        <p className="text-xl md:text-3xl font-semibold px-5 md:px-10">
          VIDEOS
        </p>
        {videos.length === 0 ? (
          <div className="flex justify-center items-center space-x-4 pt-40 mb-5">
            <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-black"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 px-5 md:px-10 py-5 md:py-10 max-h-[100vh] overflow-y-auto bg-white scrollbar-hide">
            {videos.map((video) => (
              <article
                htmlFor="my-modal-3"
                class="rounded-lg border border-gray-300 p-4 shadow-xl  transition hover:shadow-lg sm:p-6 "
              >
                <div className="flex justify-between items-center">
                  <span
                    onClick={() => handleVideoDelete(video)}
                    class="my-4 rounded cursor-pointer bg-red-500 p-2 text-white"
                  >
                    <FaTrash className="text-xl md:text-2xl" />
                  </span>
                </div>

                <div className="flex flex-col h-80">
                  {video.youtube ? (
                    <iframe
                      src={video.youtube}
                      title="YouTube video player"
                      frameborder="0"
                      allowfullscreen
                      class="h-72"
                    ></iframe>
                  ) : (
                    <video className=" h-72" src={video?.videoLink} controls />
                  )}
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminSneharth;
