import React from 'react';
import {BsEnvelope, BsInstagram} from 'react-icons/bs'
import {SiLinkedin} from 'react-icons/si' 
import {CiTwitter} from 'react-icons/ci' 
import {FaMedium} from 'react-icons/fa' 
import {SiSubstack} from 'react-icons/si' 

const Connect = () => {
 const   mailtoHref = "mailto:snehapoddar11@gmail.com"; 
    return (
        <div className='px-5 md:px-14 xl:px-20 py-5 md:pb-10 bg-white'>
            
        <div className='flex flex-col justify-start'>
            <p className='text-2xl md:text-[28px] xl:mb-8 font-bold text-gray-800 dark:text-white'>Connect</p>     
       </div>
            <ul className='flex space-x-5 md:space-x-14 lg:space-x-10 pl-1 pt-6'>
                <li>
                    <a href={mailtoHref} target='blank'><BsEnvelope className='text-[30px] md:text-[55px] text-black'/></a>
                </li>
                <li>
                    <a href='https://www.linkedin.com/in/snehapoddar11' target='blank'><SiLinkedin className='text-[30px] md:text-[50px] text-black'/></a>
                </li>
                <li>
                    <a href='https://www.instagram.com/omnisneha' target='blank'><BsInstagram className='text-[30px] md:text-[50px]  text-black'/></a>
                </li>
                <li>
                    <a href='https://twitter.com/omnisneha11' target='blank'><CiTwitter className='text-[30px] md:text-[55px]  text-black'/></a>
                </li>
                <li>
                    <a href='https://snehapoddar11.medium.com' target='blank'><FaMedium className='text-[30px] md:text-[50px]  text-black'/></a>
                </li>
                <li>
                    <a href='https://omniverselife.substack.com' target='blank'><SiSubstack className='text-[30px] md:text-[50px]  text-black'/></a>
                </li>
            </ul>
        </div>
    );
};

export default Connect;