import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

const Updated = () => {
  const [email,setEmail]=useState('')

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const url = 'https://script.google.com/macros/s/AKfycbx4lJVczWiCDFwc8JEXtwlZ0MsFrz_qyat4Psks1LvJcc_8ivld6GQlpDDKNKfdpPQ/exec';
    console.log(formData)
    fetch(url, {
      method: 'POST',
      body: formData, 
    })
      .then((res) => res.json())
      .then((data) => {
        setEmail('');
        toast.success("Your email has been submitted successfully")
        console.log(data)
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='px-5 md:px-14 xl:px-20 py-5 md:pb-10 bg-white'>
      <div className='flex flex-col justify-start'>
        <p className='text-2xl lg:text-[28px] mb-2 font-bold text-gray-800'>Stay Updated</p>
      </div>
      <p className='text-[20px] text-black font-bold pb-3'>Join mailing list</p>

      <form onSubmit={(e)=>handleSubmit(e)} className='flex flex-col justify-start md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-6 '>
        <input name='Email' type='email' value={email} onChange={(e) => setEmail(e.target.value)}  className=' px-2 text-lg font-semibold h-[43px] w-[280px] md:w-[313px] rounded-[16px] border-black border-2 bg-white text-gray-900' />
        <button className='bg-black text-[20px] text-white rounded-[16px]  w-[120px] h-[43px]'>Submit</button>
      </form>
    </div>
  );
};

export default Updated;



// import React, { useState } from 'react';
// import axios from 'axios'
// import { toast } from 'react-hot-toast';

// const Updated = () => {
//     const [email, setEmail] = useState('')

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const formFile = document.querySelector("form")
//         console.log('connect');
//         const formData = new FormData(formFile)
//         // const data={
//         //   Email:email
//         // }
//         // try {
//         //   const response = await axios.post('https://sheet.best/api/sheets/d675f643-a0dd-4608-af4a-06a13b9cfc47',data);
//         //   console.log(response);
//         //   toast.success('Your response is submitted successfully!!!')
//         //   setEmail('');
//         // } catch (error) {
//         //   console.log(error);
//         // }
//         fetch('https://script.google.com/macros/s/AKfycbyxbeI_DhDQNoITHZENRJ74TxNYZO4CDVyt7ZDNUGg0nzsTZddOw3f6z-FXXFY7RMY/exec',{
//           method:"POST",
//           body: formData,
//         }).then(res=>res.json()).then(data=>console.log(data)).catch(err=>console.log(err))
//       };

//     return (
//         <div className='px-5 md:px-14 xl:px-20 py-5 md:pb-10 bg-white'>
            
//         <div className='flex flex-col justify-start'>
//             <p className='text-2xl lg:text-[28px] mb-2 font-bold text-gray-800'>Stay Updated</p>     
//        </div>
//             <p className='text-[20px] text-black font-bold pb-3'>Join mailing list</p>

//         <form onSubmit={(e)=>handleSubmit(e)} className='flex flex-col justify-start md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-6 '>
//             <input name='Email' type='email' className=' px-2 text-lg font-semibold h-[43px] w-[280px] md:w-[313px] rounded-[16px] border-black border-2 bg-white text-gray-900'/>
//             <button className='bg-black text-[20px] text-white rounded-[16px]  w-[120px] h-[43px]'>Submit</button>
//         </form>
//         </div>
//     );
// };

// export default Updated;