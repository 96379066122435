import React, { useEffect, useState } from "react";
import { GoPrimitiveDot } from "react-icons/go";

const Honor = () => {
  const [honorArray, setHonorArray] = useState([]);

  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/honor")
      .then((response) => response.json())
      .then((data) => setHonorArray(data))
      .catch((error) => console.error(error));
  }, []);
  return (
    <div className="flex flex-col px-0 pt-5 md:pt-10 md:pb-0 xl:px-8 space-y-10 space-x-10 lg:space-y-0">
      <div className="flex flex-col justify-start">
        <p className="text-2xl lg:text-[28px] -mb-4 lg:mb-8 font-bold text-gray-800">
          Honors
        </p>
      </div>
      <div>
        {honorArray.map((item) => (
          <ul key={item.id} className="space-y-4 -ml-8">
            {item.honor.map((hnr) => (
              <li key={hnr} className="flex items-center space-x-6">
                <GoPrimitiveDot className="font-bold text-20px md:text-[18px] lg:text-[15px] text-[#1A1A1A]" />
                <p className="text-sm md:text-[15px] lg:text-[26px] leading-6 lg:leading-[40px] xl:leading-[53px] tracking-wide xl:tracking-wider break-words xl:text-[19px] text-[#1A1A1A] selection:bg-blue-400 selection:text-white">
                  {hnr}
                </p>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default Honor;
// F
//ALL INDIA 10th RANK, CA - Institute of Chartered Accountants of India
//AMUL VIDYA BHr outstandingc performance in HSC Board Examination//
// FEATURED IN CENTUM SECRETS, 2019 - by Navin Jain Bachawat
//ALL INDIA 10th RANK, CA  outstanding academic performance in HSC Board Examination//
