
import {  useState } from "react";
import { Link } from "react-router-dom";

 const AdminNavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);


  //   const [isDarkMode, setIsDarkMode] = useState(
  //   localStorage.theme === "dark" ||
  //     (!("theme" in localStorage) &&
  //       window.matchMedia("(prefers-color-scheme: dark)").matches)
  // );

  // useEffect(() => {
  //   if (isDarkMode) {
  //     document.documentElement.classList.add("dark");
  //   } else {
  //     document.documentElement.classList.remove("dark");
  //   }
  // }, [isDarkMode]);

  // const handleToggleTheme = () => {
  //   setIsDarkMode((prevMode) => !prevMode);
  //   localStorage.theme = isDarkMode ? "light" : "dark";
  // 
  //<li onClick={handleToggleTheme}>
  //{
  //                        isDarkMode ?  <FaSun className='h-10 w-7 text-white'/>  : <FaMoon className='h-10 w-7 text-black'/>
  //                    }
  //</li>
//};

    
 
    

  
    return (
      <div class="bg-white  text-gray-900">
        <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-14  lg:px-14 xl:px-0 lg:py-[20px]">
          <div class="relative flex items-center justify-between ">
          
              <span class="ml-2 text-xl md:text-[20px] font-bold tracking-wide text-gray-800  uppercase">
                Sneha Poddar
              </span>
            
            <ul class="flex  items-center hidden space-x-[50px] lg:flex ">
            
            
                  <li className="font-medium text-xl tracking-wide text-gray-800 ">
               
                  <Link to='/AdminSnehaP'>About</Link>  
                </li>
                <li className="font-medium text-xl tracking-wide text-gray-800">
            
                  <Link to='add-honor'>Honor</Link>

              </li>
              <li className="font-medium text-xl tracking-wide text-gray-800 ">
                
                 <Link to='add-blog'>Blog</Link>
                
              </li>
             
              <li className="font-medium text-xl tracking-wide text-gray-800">
            
                  <Link to='add-requirment'>Join the team</Link>

              </li>
              <li className="font-medium text-xl tracking-wide text-gray-800 ">
                 
              <Link to='add-media'>Media</Link> 
                 
              </li>
             
                
               <Link to='/AdminSnehaP/snearth'>
                  <li className="font-medium text-xl tracking-wide text-gray-800 ">Snearth</li>
                    </Link>
           
           
            </ul>
            <div class="lg:hidden">
              <button
                
                         class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div class="absolute top-0 left-0 w-full">
                  <div class="p-5 bg-white border rounded shadow-sm">
                    <div class="flex items-center justify-between mb-4">
                      <div>
                        <a
                          href="/"
                                           class="inline-flex items-center"
                        >
                          <svg
                            class="w-8 text-deep-purple-accent-400"
                            viewBox="0 0 24 24"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            stroke="currentColor"
                            fill="none"
                          >
                            <rect x="3" y="1" width="7" height="12" />
                            <rect x="3" y="17" width="7" height="6" />
                            <rect x="14" y="1" width="7" height="6" />
                            <rect x="14" y="11" width="7" height="12" />
                          </svg>
                          <span class="ml-2 text-xl font-bold tracking-wide text-gray-800  uppercase">
                         Sneha Poddar
                          </span>
                        </a>
                      </div>
                      <div>
                        
                      </div>
                    </div>
                    <nav>
                      <ul class="space-y-4">
                        <li onClick={()=>setIsMenuOpen(false)} className="font-medium text-xl tracking-wide text-gray-800">
                    
                        <Link to='/AdminSnehaP'>About</Link> 

                        </li>
                        <li onClick={()=>setIsMenuOpen(false)} className="font-medium text-xl tracking-wide text-gray-800">
            
                        <Link to='add-honor'>Honor</Link>

                        </li>
                        <li onClick={()=>setIsMenuOpen(false)} className="font-medium text-xl tracking-wide text-gray-800">
            
                        <Link to='add-blog'>Blog</Link>

                        </li>
                        
                        <li onClick={()=>setIsMenuOpen(false)} className="font-medium text-xl tracking-wide text-gray-800">
            
                         <Link to='add-requirment'>Join the team</Link>

                        </li>
                        <li onClick={()=>setIsMenuOpen(false)} className="font-medium text-xl tracking-wide text-gray-800">
             
                        <Link to='add-media'>Media</Link> 

                        </li>
                      
                        <Link to='/AdminSnehaP/snearth'>
                  <li onClick={()=>setIsMenuOpen(false)} className="font-medium text-xl tracking-wide text-gray-800  mt-4">Sneharth</li>
                    </Link>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default AdminNavBar;