import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { GoDeviceCamera } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Upload from "./Upload";
import UploadFromYoutube from "./UploadFromYoutube";

const AddMedia = () => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [caption, setCaption] = useState("");
  const navigate = useNavigate();
  const imgBbKey = "f375a7d7cbf6443df6506145faff4414";

  // display im

  const [previewImage, setPreviewImage] = useState();
  const check = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewImage(e.target.files[0]);
    }
  };

  const handleAddImage = (event) => {
    event.preventDefault();

    const form = event.target;
    const description = form.description.value;
    const img = form.img.files[0];

    const formData = new FormData();
    formData.append("image", img);
    const url = `https://api.imgbb.com/1/upload?key=${imgBbKey}`;
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((imgData) => {
        if (imgData.success) {
          const mediaInfo = {
            description,
            image: imgData.data.url,
          };

          fetch(
            "https://sneha-poddar-server-70f8de076d6f.herokuapp.com/media",
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(mediaInfo),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.acknowledged) {
                toast.success(`You have added a media successfully`);

                form.reset("");
                navigate("/AdminSnehaP/snearth");
              } else {
                alert("Error");
              }
            })
            .catch((err) => alert(err));
        }
      });
  };

  return (
    <div className="bg-gray-100 flex   justify-center items-start py-10 px-3 md:px-10 lg:py-0 lg:items-center min-h-screen">
      <div className="flex  justify-center items-start lg:items-center  py-16 min-h-screen px-3  ">
        <div className="flex flex-col items-center space-y-8">
          {/*  */}
          <div className="flex flex-col items-center space-y-1 shadow-lg w-[330px] md:w-[800px] px-3 md:p-10 md:space-y-3 border-2 rounded-md bg-white">
            <h1 className="text-sm md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2 pt-3">
              Add an image to Snearth{" "}
              <GoDeviceCamera className="inline-block text-teal-accent-400" />
            </h1>

            <div
              onChange={previewImage}
              className="hover:scale-110 duration-300"
            >
              {previewImage ? (
                <img
                  className="h-72 w-60"
                  src={URL.createObjectURL(previewImage)}
                  alt=""
                />
              ) : (
                <img
                  className="h-40 w-40 md:h-72 md:w-60 mb-5"
                  src="https://img.freepik.com/free-photo/woman-taking-photos-heart-shaped-macarons-planes-with-smartphone_23-2149428227.jpg?size=626&ext=jpg&ga=GA1.1.1540219272.1675657721&semt=ais"
                  alt=""
                />
              )}
            </div>

            <form
              onSubmit={handleAddImage}
              className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg  hover:shadow-lg w-full"
            >
              <input
                className=" px-3 py-2 rounded-lg shadow-sm border  border-none w-full focus:outline-none  bg-white "
                type="file"
                name="img"
                id=""
                onChange={check}
                accept="image/*"
                required
              />

              <textarea
                name="description"
                placeholder="Add a caption (optional)"
                className="w-full h-20 pt-3 px-2 text-xl border border-gray-200 bg-white rounded-md outline-none"
              ></textarea>
              <div className="flex items-center space-x-2"></div>

              <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-200 shadow-md rounded-md ">
                Save now
              </button>
            </form>
          </div>
          {/* video */}
          <Upload />
          <UploadFromYoutube />
        </div>
        {/* <video src='https://res.cloudinary.com/dv51da0o9/video/upload/v1681295742/vvfbwjv0zk0dsgtvqf8x.mp4' controls /> */}
      </div>

      <div></div>
    </div>
  );
};

export default AddMedia;
