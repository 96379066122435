import React, { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";

const AddHonor = () => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleClick = async () => {
    try {
      await fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/honors/642d3b7bd82a2466774f4c31`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ honor: inputValue }),
        }
      );
      setInputValue("");
      alert("done");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="bg-gray-100 flex  justify-center items-start py-10 px-3 md:px-10 lg:py-0 lg:items-center min-h-screen">
      <div className="flex flex-col justify-center items-center space-y-1  w-[800px] px-5 lg:p-10 md:space-y-3 border-2 rounded-md bg-white">
        <h1 className="text-xl py-2 md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2">
          Add Honor{" "}
          <FaPencilAlt className="inline-block text-teal-accent-400 mb-2" />
        </h1>

        <form
          onSubmit={handleClick}
          className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
        >
          <textarea
            value={inputValue}
            onChange={handleChange}
            placeholder="Honor"
            className="w-full h-20 md:h-32 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none"
          ></textarea>

          <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
            Save now
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddHonor;
