import React, { useEffect, useState } from "react";

import AdminBlogCard from "./AdminBlogCard";

const AdminBlog = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/all-blogs")
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
      });
  }, [blogs]);

  return (
    <div id="blog" className="px-5 md:px-14 xl:px-20  pb-7 bg-gray-100">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center pt-2 pb-6">
        <h1 className="text-2xl lg:text-[28px] font-bold text-gray-800">
          Blogs
        </h1>

        <div>
          {/* <label htmlFor="my-modal-3" className=' text-[20px] font-bold text-black'>View all</label>
            <AllBlogModal/> */}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-x-[83px] lg:gap-x-[68px] xl:gap-x-40 max-h-[100vh] overflow-y-auto scrollbar-hide">
        {blogs.map((blog) => (
          <div className="bg-[#fff] border  w-full lg:w-[250px] xl:w-[330px] rounded-md shadow-md ">
            <AdminBlogCard blog={blog} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminBlog;
