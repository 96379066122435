import React from 'react';
import Connect from '../Connect/Connect';
import Updated from '../Updated/Updated';

const DoubleComponent = () => {
    return (
        
      <div className='flex flex-col items-start xl:flex-row justify-between xl:items-center bg-white'>
      <Connect/>
      <Updated/>
    </div>
    );
};

export default DoubleComponent;