import React, { useContext, useEffect, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import { UserContext } from "../../../context/Context";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const SnearthModal = () => {
  const [medias, setMedias] = useState([]);
  const { index, setIndex } = useContext(UserContext);
  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/all-media")
      .then((res) => res.json())
      .then((data) => {
        setMedias(data);
      });
  }, []);
  console.log(index);
  const k = useLoaderData();

  const nextSlide = () => {
    setIndex(index + 1);
  };
  const prevSlide = () => {
    setIndex(index - 1);
  };

  const isFirstSlide = index === 0;
  const isLastSlide = index === medias.length - 1;

  return (
    <div className="flex flex-col justify-center items-center px-5 py-5 md:pt-10 md:px-10  bg-black">
      <div className="min-w-full  min-h-screen py-3">
        <div className="flex justify-between py-5">
          <RxCrossCircled className=" text-black text-3xl" />

          <Link lassName="text-right " to="/snearth">
            <RxCrossCircled className="text-white text-xl md:text-3xl" />
          </Link>
        </div>

        <div className="flex flex-col justify-center items-center w-full ">
          <div className="flex flex-col justify-center items-center w-full  relative">
            <img
              src={medias[index]?.image}
              className="w-[350px] h-[500px] px-4 md:w-[400px] lg:w-[600px] md:h-[400px]  lg:h-[600px] xl:w-[900px] xl:h-[900px] object-cover"
              alt=""
            />

            <FaArrowRight
              onClick={nextSlide}
              className={`absolute text-sm md:text-2xl right-0 ${
                isLastSlide
                  ? "text-black cursor-not-allowed"
                  : "text-white cursor-pointer"
              }`}
            />
            <FaArrowLeft
              onClick={prevSlide}
              className={`absolute text-sm md:text-2xl left-0 ${
                isFirstSlide
                  ? "text-black cursor-not-allowed"
                  : "text-white cursor-pointer"
              }`}
            />
          </div>
          <p className="text-xl text-white text-center font-light py-5">
            {medias[index]?.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SnearthModal;
