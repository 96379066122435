import React, { useEffect, useState } from "react";

import sneha2 from "../../assets/s2.png";
import Honor from "./Honor";
const About = () => {
  const [about, setAbout] = useState([]);

  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/about")
      .then((response) => response.json())
      .then((data) => setAbout(data))
      .catch((error) => console.error(error));
  }, []);

  console.log(about);

  return (
    <div id="about" className="px-5 py-5 md:px-14 md:py-10 bg-white ">
      <div className="flex flex-col justify-start  xl:px-8 my-5">
        <p className="text-3xl lg:text-[28px] font-bold text-[#1A1A1A]">
          About
        </p>
      </div>
      {about.map((a) => (
        <div className="flex flex-col-reverse lg:flex-row lg:justify-between lg:items-start  space-y-4 lg:space-y-0">
          <div className="w-full lg:w-1/2 space-y-2 lg:space-y-5 pr-0 lg:pr-10 xl:px-8 ">
            <p
              className="text-xs   md:text-[20px] lg:text-[16px] xl:text-[23px] xl:leading-[34px] lg:tracking-normal xl:tracking-wide font-normal 
                  leading-[24px] md:leading-[30px] text-[#1A1A1A] "
            >
              {a.first}
            </p>

            <p
              className="text-xs  xl:py-3 md:text-[20px] lg:text-[16px] xl:text-[23px] xl:leading-[34px] lg:tracking-normal xl:tracking-wide font-normal
                  leading-[24px] md:leading-[30px] text-[#1A1A1A] "
            >
              {a.middle}
            </p>

            <p
              className="text-xs  md:text-[20px] lg:text-[16px] xl:text-[23px] xl:leading-[34px] lg:tracking-normal xl:tracking-wide font-normal
                  leading-[24px] md:leading-[30px] text-[#1A1A1A] "
            >
              {a.last}
            </p>
          </div>

          <div className="w-full lg:w-1/2">
            {/* <img className=' md:w-full   ml-0 md:ml-0 mb-7 lg:mb-0 z-40 bg-gray-100 xl:hidden' src={sneha} alt=''/> */}
            <img
              className=" w-full pb-4 lg:pb-0  xl:w-[520px]  xl:ml-28  z-40 bg-gray-100"
              src={a.image}
              alt=""
            />
          </div>
        </div>
      ))}
      <Honor />
    </div>
  );
};

export default About;
