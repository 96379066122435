import React from 'react';
import banner from '../../assets/mbl.jpeg'
import bnr from  '../../assets/xlb.jpeg'
import bnr2 from  '../../assets/hero.jpeg'


const HeroSection = () => {
    return (<div className='z-0'>
                {/* mobile */}
                <div className="back-ground bg-cover bg-center w-full h-[560px] sm:h-[620px] relative md:hidden" style={{ backgroundImage: `url(${banner})` }}>
                    <h1 className='text-white text-sm md:text-[20px] capitalize font-semibold 
                   w-full px-5  leading-[30px] absolute bottom-4'>We're capable of incredible accomplishment,
                    which will be achieved for the purpose of social and spiritual transformation, but we have to 
                    challenge ourselves before we'll know what we can really do.

                    </h1>
                </div>
                  {/*  tab and large device*/}
                <div className="back-ground bg-cover bg-center w-full h-96 md:h-[600px] hidden md:block xl:hidden " style={{ backgroundImage: `url(${bnr})` }}>
                    <h1 className='text-white text-sm md:text-[20px] capitalize font-semibold 
                    md:font-bold w-full md:w-full lg:w-[575px] leading-[31px] px-2 md:pr-80 lg:pr-20 xl:pr-20 pt-20 
                    md:pt-[260px] lg:pt-[260px] xl:pt-[240px] md:ml-3 lg:ml-5'>We're capable of incredible accomplishment,
                     which will be achieved for the purpose of social and spiritual transformation, but we have to 
                     challenge ourselves before we'll know what we can really do.
                    </h1>
                </div>


          {/* extra large */}
                <div className="back-ground bg-cover bg-center w-full h-96 md:h-[600px] hidden xl:block" style={{ backgroundImage: `url(${bnr2})` }}>
                    <h1 className='text-white text-sm md:text-[20px] capitalize font-semibold 
                    md:font-bold w-full md:w-full lg:w-[575px] leading-[30px] px-2 md:pr-72 lg:pr-20 xl:pr-20 pt-20 
                    md:pt-52 lg:pt-[140px] xl:pt-[270px] md:ml-3 xl:ml-3'>We're capable of incredible accomplishment, 
                    which will be achieved for the purpose of social and spiritual transformation, but we have to 
                    challenge ourselves before we'll know what we can really do.
                    </h1>
                </div>
        </div>
    );
};

export default HeroSection;
