import React from "react";
import { toast } from "react-hot-toast";
import { FaYoutube } from "react-icons/fa";

const UploadFromYoutube = () => {
  const handleUploadFromYoutube = (event) => {
    event.preventDefault();

    const form = event.target;
    const youtube = form.youtube.value;

    const mediaInfo = {
      videoLink: "",
      youtube,
    };

    fetch(
      "https://sneha-poddar-server-70f8de076d6f.herokuapp.com/youtube-upload",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(mediaInfo),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          toast.success(`You have upload a a video from youtube successfully`);

          form.reset("");
        } else {
          alert("Error");
        }
      })
      .catch((err) => alert(err));
  };

  return (
    <div className="bg-white mt-5">
      <div className="flex flex-col items-center space-y-1 shadow-lg w-[330px] md:w-[800px] px-3 md:p-10 md:space-y-3 border-2 rounded-md bg-white">
        <h1 className="text-sm md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2 pt-3">
          Upload from youtube{" "}
          <FaYoutube className="inline-block text-teal-accent-400" />
        </h1>

        <form
          onSubmit={handleUploadFromYoutube}
          className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg  hover:shadow-lg w-full"
        >
          <input
            name="youtube"
            placeholder="Copy a link from youtube & paste here"
            className="w-full h-20 pt-3 px-2 text-xl border border-gray-200 bg-white rounded-md outline-none"
          />
          <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-200 shadow-md rounded-md ">
            Upload now
          </button>
        </form>
      </div>
    </div>
  );
};

export default UploadFromYoutube;
