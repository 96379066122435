import React, { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";

const AddReq = () => {
  const [firstInputValue, setFirstInputValue] = useState("");
  const [secondInputValue, setSecondInputValue] = useState("");
  const [thirdInputValue, setThirdInputValue] = useState("");
  const [newInputValue, setNewInputValue] = useState("");

  //first req
  const handleFirstChange = (e) => {
    e.preventDefault();
    setFirstInputValue(e.target.value);
  };

  const handleFirstReq = async () => {
    try {
      await fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/first-requirment/642e6aa855d433da7464a787`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ requirmentes: firstInputValue }),
        }
      );
      setFirstInputValue("");
      alert("done");
    } catch (err) {
      console.error(err);
    }
  };

  //2nd req
  const handleSecondChange = (e) => {
    e.preventDefault();
    setSecondInputValue(e.target.value);
  };
  const handleSecondReq = async () => {
    try {
      await fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/second-requirment/642e6aa855d433da7464a787`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ requirmentes: secondInputValue }),
        }
      );
      setSecondInputValue("");
      alert("done");
    } catch (err) {
      console.error(err);
    }
  };

  //3rd req
  const handleThirdChange = (e) => {
    e.preventDefault();
    setThirdInputValue(e.target.value);
  };
  const handleThirdReq = async () => {
    try {
      await fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/third-requirment/642e6aa855d433da7464a787`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ requirmentes: thirdInputValue }),
        }
      );
      setThirdInputValue("");
      alert("done");
    } catch (err) {
      console.error(err);
    }
  };

  //add new
  const handleNewChange = (e) => {
    e.preventDefault();
    setNewInputValue(e.target.value);
  };
  const handleAddNewReq = async () => {
    try {
      await fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/new-requirment/642e6aa855d433da7464a787`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ requirmentes: newInputValue }),
        }
      );
      setNewInputValue("");
      alert("done");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="bg-gray-100 flex  justify-center items-start py-10 px-3 md:px-10 lg:py-0 lg:items-center min-h-screen">
      <div className="flex flex-col justify-center items-center space-y-1 mt-5 w-[800px] px-5 lg:p-10 md:space-y-3 border-2 rounded-md bg-white">
        <h1 className="text-xl py-2 md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2">
          Update/Add Requirments{" "}
          <FaPencilAlt className="inline-block text-teal-accent-400 mb-2" />
        </h1>

        {/* 1st rqmnt */}
        <form
          onSubmit={handleFirstReq}
          className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
        >
          <lable className="text-base md:text-xl font-semibold pl-2 py-2">
            Update first requirment
          </lable>
          <textarea
            onChange={handleFirstChange}
            value={firstInputValue}
            placeholder="First requirment"
            className="w-full h-20 md:h-20 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none"
          ></textarea>
          <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
            Update now
          </button>
        </form>

        {/* 2nd rqmnt */}
        <form
          onSubmit={handleSecondReq}
          className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
        >
          <lable className="text-base md:text-xl font-semibold pl-2 py-2">
            Update second requirment
          </lable>
          <textarea
            onChange={handleSecondChange}
            value={secondInputValue}
            placeholder="Second requirment"
            className="w-full h-20 md:h-20 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none"
          ></textarea>
          <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
            Update now
          </button>
        </form>

        {/* 3rd rqmnt */}
        <form
          onSubmit={handleThirdReq}
          className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
        >
          <lable className="text-base md:text-xl font-semibold pl-2 py-2">
            Update third requirment
          </lable>
          <textarea
            onChange={handleThirdChange}
            value={thirdInputValue}
            placeholder="Third requirment"
            className="w-full h-20 md:h-20 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none"
          ></textarea>
          <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
            Update now
          </button>
        </form>

        <div className="divider text-xl font-semibold py-5">OR</div>

        <form
          onSubmit={handleAddNewReq}
          className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
        >
          <lable className="text-base md:text-xl font-semibold pl-2 py-2">
            Add new requirment
          </lable>
          <textarea
            type="text"
            onChange={handleNewChange}
            value={newInputValue}
            placeholder="New requirment"
            className="w-full h-20 md:h-20 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none"
          ></textarea>
          <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
            Save now
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddReq;
