import React from "react";
import { toast } from "react-hot-toast";
import { FaTrash } from "react-icons/fa";

const AdminBlogCard = ({ blog }) => {
  const { img, link, description, title, date, platform } = blog;
  const shortDes =
    description.length > 120
      ? `${description.substring(0, 120)}...`
      : description;
  const shortDesM =
    description.length > 150
      ? `${description.substring(0, 165)}...`
      : description;
  const handleDelete = (blog) => {
    const agree = window.confirm("Are you ready to delete ?");
    if (agree) {
      fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/blog/${blog._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This blog has been deleted successfully !");
          }
        });
    }
  };

  return (
    <div>
      <div className="flex flex-col justify-between items-start ">
        <div className="flex justify-between items-center">
          <span class="my-4 rounded cursor-pointer bg-red-500 p-2 text-white ml-3">
            <FaTrash
              onClick={() => handleDelete(blog)}
              className="text-xl md:text-2xl "
            />
          </span>
        </div>
        <img className="h-[220px] w-full md:w-[352px] " src={img} alt="blog" />
        <div className="relative h-[359px] md:h-[330px] lg:h-[335px] xl:h-[279px]">
          <h2
            title={title}
            className="text-[#21243D] text-[20px] md:text-[22px] font-semibold px-3 pt-3"
          >
            {title}
          </h2>

          <div className="flex justify-between items-center my-4">
            <p className="text-[#94A4B5] text-[16px] px-3">{date}</p>
            <p className="text-[#94A4B5] text-[16px] px-3">{platform}</p>
          </div>
          <p className="text-[#7B8895]  text-[17px] font-light px-3 selection:bg-gray-400 selection:text-white md:hidden">
            {shortDesM}
          </p>
          <p className="text-[#7B8895]  text-[17px] font-light px-3 selection:bg-gray-400 selection:text-white hidden md:block">
            {shortDes}
          </p>

          <button className="px-3 text-[#21243D] text-[18px] font-semibold py-4 absolute -bottom-2">
            {" "}
            <a href={link} target="blank">
              Read more
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminBlogCard;
