import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { BsEnvelope, BsInstagram } from 'react-icons/bs';
import { CiTwitter } from 'react-icons/ci';
import { FaMedium } from 'react-icons/fa';
import { SiLinkedin, SiSubstack } from 'react-icons/si';

const AdminDoubleComponent = () => {
    const   mailtoHref = "mailto:snehapoddar11@gmail.com"; 

    // stay updated
    const [email,setEmail]=useState('')

  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const url = 'https://script.google.com/macros/s/AKfycbziAMop51YXGT19SrDL74Ta_h_ELUmF0nBn6jmkdDc3vdWn8VvdfehytaKLbHXFBOs/exec';
      
      fetch(url, {
        method: 'POST',
        body: formData,
       
      })
        .then((res) => res.json())
        .then((data) => {
          setEmail('');
          toast.success("Your email has been submited successfully")
          console.log(data)})
        .catch((err) => console.log(err));
    };
    return (
        <div  className='flex flex-col items-start xl:flex-row justify-between xl:items-center bg-black'>
            
            {/* 1 */}
            <div className='px-5 md:px-14 xl:px-20 py-5 md:pb-10'>
            
        <div className='flex flex-col justify-start'>
            <p className='text-2xl md:text-[28px] xl:mb-8 font-bold text-white'>Connect</p>     
       </div>
            <ul className='flex space-x-5 md:space-x-14 lg:space-x-10 pl-1 pt-6'>
                <li>
                    <a href={mailtoHref} target='blank'><BsEnvelope className='text-[30px] md:text-[55px] text-white'/></a>
                </li>
                <li>
                    <a href='https://www.linkedin.com/in/snehapoddar11' target='blank'><SiLinkedin className='text-[30px] md:text-[50px] text-white'/></a>
                </li>
                <li>
                    <a href='https://www.instagram.com/omnisneha' target='blank'><BsInstagram className='text-[30px] md:text-[50px]  text-white'/></a>
                </li>
                <li>
                    <a href='https://twitter.com/omnisneha11' target='blank'><CiTwitter className='text-[30px] md:text-[55px]  text-white'/></a>
                </li>
                <li>
                    <a href='https://snehapoddar11.medium.com' target='blank'><FaMedium className='text-[30px] md:text-[50px]  text-white'/></a>
                </li>
                <li>
                    <a href='https://omniverselife.substack.com' target='blank'><SiSubstack className='text-[30px] md:text-[50px]  text-white'/></a>
                </li>
            </ul>
        </div>
        {/* 2 */}

        <div className='px-5 md:px-14 xl:px-20 py-5 md:pb-10 '>
      <div className='flex flex-col justify-start'>
        <p className='text-2xl lg:text-[28px] mb-2 font-bold text-white'>Stay Updated</p>
      </div>
      <p className='text-[20px] text-white font-bold pb-3'>Join mailing list</p>

      <form onSubmit={(e)=>handleSubmit(e)} className='flex flex-col justify-start md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-6 '>
        <input name='Email' type='email' value={email} onChange={(e) => setEmail(e.target.value)}  className=' px-2 text-lg font-semibold h-[43px] w-[280px] md:w-[313px] rounded-[16px] border-black border-2 bg-white text-gray-900' />
        <button className='bg-white font-bold text-[20px] text-black rounded-[16px]  w-[120px] h-[43px]'>Submit</button>
      </form>
    </div>
       
        </div>
    );
};

export default AdminDoubleComponent;