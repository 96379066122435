import React, { useState } from "react";
import { toast } from "react-hot-toast";

const UpdateCaption = ({ _id }) => {
  const [updated, setUpdated] = useState(false);

  const handleUpdateCaption = async (e) => {
    e.preventDefault();
    const form = e.target;
    const newDescription = { _id: _id, description: form.description.value };

    try {
      const response = await fetch(
        `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/change-caption/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newDescription),
        }
      );

      const data = await response.json();

      if (data.modifiedCount > 0) {
        toast.success("This caption is updated successfully");
        setUpdated(true);
      }

      form.reset();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="bg-white">
      {updated ? null : (
        <div>
          <input type="checkbox" id="mymodal" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="mymodal"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>

              <h3 className="font-bold text-lg">Update the caption!</h3>
              <form
                onSubmit={handleUpdateCaption}
                className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
              >
                <textarea
                  name="description"
                  className="w-full h-20 md:h-32 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none"
                ></textarea>
                <button
                  type="submit"
                  className="bg-[#fff] w-full text-center text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold shadow-gray-500 shadow-md rounded-md"
                >
                  Update!
                </button>
              </form>
              <div></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateCaption;
