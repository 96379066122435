import React from 'react';

import About from '../components/About/About'
import Blog from '../components/Blog/Blog'
import JoinTeam from '../components/JoinTeam/JoinTeam'
import Consult from '../components/Consult/Consult';
import Connect from '../components/Connect/Connect';
import Updated from '../components/Updated/Updated';
import DoubleComponent from '../components/DoubleComponent/DoubleComponent';
import HeroScetions from '../components/HeroSection/HeroScetions';
import HeroSection from '../components/HeroSection/HeroSection';
const HomePage = () => {
    return (
        <div>
           <HeroSection/>
            <About/>  
            <Blog/>
            <Consult/>
            <JoinTeam/>
            <DoubleComponent/>
    
        </div>
    );
};

export default HomePage;