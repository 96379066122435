import React from 'react';

const Consult = () => {
    return (
        <div id='consult' className='px-5 md:px-14 xl:px-20 py-5 md:py-10 md:pb-10 bg-white '>
                <div className='flex flex-col justify-start'>
                    <p className='text-2xl lg:text-[28px] xl:mb-8 font-bold text-gray-800 pb-3 lg:pb-0'>Consult</p>     
               </div>
                <p className='text-[15px] md:text-[20px] text-black'>Get in touch for consultancy 
                and advisory services in the domains of systems thinking, social design, experience 
                design, development economics, policy-making, strategy, technology, business, 
                management, finance, and accountancy.</p>  

                <div className='flex flex-col md:flex-row space-x-0 space-y-2 md:space-y-0 md:space-x-4 items-center my-5'>
                    <p className='text-[15px] md:text-[20px] text-[#6C6C6C]'>Discover what’s possible. Write to </p>
                    <buttton className='w-[250px] h-[35px] md:w-[333px]  md:h-[43px] rounded-[10px] md:rounded-[16px] text-center pt-1 bg-black text-white text-[15px] md:text-[20px]'>snehapoddar11@gmail.com</buttton>
                </div>          
        </div>
    );
};

export default Consult;