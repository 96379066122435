import React from 'react';

const BlogCard = ({blog}) => {
    const { img, link, description, title, date, platform} = blog
    const shortDes = description.length >120 ? `${description.substring(0,120)}...` : description;
    const shortDesM = description.length >150 ? `${description.substring(0,165)}...` : description;
    const shortTitle = title.length > 25 ? `${title.substring(0,25)}...` : title;
    return (
        <div>
           <div className='flex flex-col justify-between items-start '>
            <img className='h-[220px] w-full md:w-[352px] ' src={img} alt='blog'/>        
            <div className='relative h-[359px] md:h-[330px] lg:h-[335px] xl:h-[279px]'>
            <h2 title={title} className='text-[#21243D] text-[20px] md:text-[22px] font-semibold px-3 pt-3'>{title}</h2>

            <div className='flex justify-between items-center my-4'>

                <p className='text-[#94A4B5] text-[16px] px-3'>{date}</p>
                <p className='text-[#94A4B5] text-[16px] px-3 capitalize'>{platform}</p>

            </div>
            <p className='text-[#7B8895]  text-[17px] font-light px-3 selection:bg-gray-400 selection:text-white md:hidden'>{shortDesM}</p>
            <p className='text-[#7B8895]  text-[17px] font-light px-3 selection:bg-gray-400 selection:text-white hidden md:block'>{shortDes}</p>
            
            <button className='px-3 text-[#21243D] text-[18px] font-semibold py-4 absolute -bottom-2'> <a href={link} target='blank'>Read more</a></button>
            </div>
          </div>      
        </div>
    );
};

export default BlogCard;
