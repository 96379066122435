import React, { useEffect, useState } from "react";
import { GoPrimitiveDot } from "react-icons/go";

const JoinTeam = () => {
  const [requirments, setRequirments] = useState([]);
  console.log("honoth", requirments);
  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/join-team")
      .then((response) => response.json())
      .then((data) => setRequirments(data))
      .catch((error) => console.error(error));
  }, []);
  return (
    <div id="team" className="px-5 md:px-14 xl:px-20 py-5 md:pb-10 bg-white ">
      <div className="flex flex-col justify-start">
        <p className="text-2xl md:text-[28px] xl:mb-8 font-bold text-gray-800 pb-3 lg:pb-0">
          Join the team
        </p>
      </div>

      <div>
        <p className="text-[15px] text-gray-800  md:text-[20px]">
          Interested in working together?
        </p>
        <p className="text-[15px] text-gray-800 md:text-[20px]">
          Email your aspiration, interest, skillset and motivation at{" "}
          <b>snehapoddar11@gmail.com</b>
        </p>
      </div>

      <p className="text-[15px] text-gray-800 md:text-[20px] py-6">
        Currently looking for help in the following domains:
      </p>

      {requirments.map((requirment) => (
        <ul key={requirment.id} className="space-y-4">
          {requirment.requirmentes.map((rqr) => (
            <li key={rqr} className="flex items-center space-x-3 md:space-x-6">
              <GoPrimitiveDot className="font-bold text-15px md:text-[15px] lg:text-[15px] text-[#1A1A1A]" />
              <p className="text-sm xl:text-[20px] text-[#1A1A1A]">{rqr}</p>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};
{
  /* <li className='flex items-center space-x-3 md:space-x-6'><GoPrimitiveDot className='font-bold text-15px md:text-[15px] lg:text-[15px] text-[#1A1A1A]'/><p className='text-sm xl:text-[20px] text-[#1A1A1A]'>PR and outreach</p></li> */
}
export default JoinTeam;
