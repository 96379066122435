import { useState } from "react";
import { CloudinaryContext, Video } from "cloudinary-react";
import { GoDeviceCameraVideo } from "react-icons/go";
import { toast } from "react-hot-toast";

const Upload = () => {
  const [videoFilee, setVideoFilee] = useState(null);
  const [progress, setProgress] = useState(0);
  const [preview, setPreview] = useState(null);

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    setVideoFilee(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  const handleUploadClick = async (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("video-upload-input");
    const file = fileInput.files[0];

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "nn648eww");

    const xhr = new XMLHttpRequest();
    xhr.open("POST", `https://api.cloudinary.com/v1_1/dv51da0o9/upload`, true);

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const progress = Math.round((e.loaded / e.total) * 100);
        setProgress(progress);
        console.log(progress);
      }
    };

    xhr.onload = async () => {
      if (xhr.status === 200) {
        const result = JSON.parse(xhr.responseText);

        const videoInfo = {
          youtube: "",
          videoLink: result.secure_url,
        };

        try {
          const response = await fetch(
            "https://sneha-poddar-server-70f8de076d6f.herokuapp.com/post-video",
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(videoInfo),
            }
          );

          const data = await response.json();

          if (data.acknowledged) {
            setVideoFilee(null);
            fileInput.value = "";

            setPreview(null);
            alert(`You have added a video successfully`);
          } else {
            alert("Error");
          }
        } catch (err) {
          console.error("Error posting video:", err);
        }
      } else {
        console.error("Error uploading video:", xhr.statusText);
      }
    };

    xhr.send(formData);
  };
  return (
    <div>
      {/* <input id="video-upload-input" type="file" accept="video/*" />
        <button onClick={handleUploadClick}>Upload</button> */}
      {/* {videoFilee && (
          <CloudinaryContext cloudName="dv51da0o9">
            <Video publicId={videoFilee.public_id} controls />
          </CloudinaryContext>
        )} */}
      <div className="flex flex-col items-center space-y-1 shadow-lg  w-[330px] md:w-[800px] px-3 md:p-10 md:space-y-3 border-2 rounded-md bg-white">
        <h1 className="text-sm md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2 pt-3">
          Add a video to Snearth{" "}
          <GoDeviceCameraVideo className="inline-block text-teal-accent-400" />
        </h1>
        <form
          onSubmit={handleUploadClick}
          className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg  hover:shadow-lg w-full"
        >
          {/* {videoFilee && (
          <CloudinaryContext cloudName="dv51da0o9">
            <Video publicId={videoFilee.public_id} controls />
          </CloudinaryContext>
        )} */}

          {preview && (
            <video className="h-60 w-full" src={preview} controls alt="" />
          )}

          <input
            className=" px-3 py-2 rounded-lg shadow-sm border  border-none w-full focus:outline-none  bg-white "
            type="file"
            name="video"
            id="video-upload-input"
            onChange={handleFileUpload}
            accept="video/*"
            required
          />

          {progress > 0 && progress < 100 ? (
            <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 space-x-0 lg:space-x-4 items-start lg:items-center ">
              <p className="text-xl font-semibold text-gray-900">
                Uploading: {progress}%
              </p>
              <progress
                className="progress w-full lg:w-56"
                value={progress}
                max="100"
              ></progress>
            </div>
          ) : (
            <p className="hidden">Uploading: {progress}%</p>
          )}

          <button
            id="saveBtn"
            className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-200 shadow-md rounded-md "
          >
            Save now
          </button>
        </form>
      </div>
    </div>
  );
};

export default Upload;
