import React from "react";
import { toast } from "react-hot-toast";
import { FaPencilAlt } from "react-icons/fa";
import AddAboutImage from "./AddAboutImage";

const AddAbout = () => {
  //https://snehas-portfolio-server.vercel.app
  const handleFirstAboutUpdate = (e) => {
    e.preventDefault();
    const form = e.target;
    const firstAbout = { first: form.firstAbout.value };

    fetch(
      `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/first-about/642fa412d6e0058d40b86b82`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(firstAbout),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success("First about is updated successfully");
        }
        form.reset("");
      })
      .catch((err) => console.error(err));
  };
  const handleMiddleAboutUpdate = (e) => {
    e.preventDefault();
    const form = e.target;
    const middleAbout = { middle: form.middleAbout.value };

    fetch(
      `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/middle-about/642fa412d6e0058d40b86b82`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(middleAbout),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success("Middle about is updated successfully");
        }
        form.reset("");
      })
      .catch((err) => console.error(err));
  };
  const handleLastAboutUpdate = (e) => {
    e.preventDefault();
    const form = e.target;
    const lastAbout = { last: form.lastAbout.value };
    console.log(lastAbout);
    fetch(
      `https://sneha-poddar-server-70f8de076d6f.herokuapp.com/last-about/642fa412d6e0058d40b86b82`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(lastAbout),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success("Last about is updated successfully");
        }
        form.reset("");
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="bg-gray-100 flex  justify-center items-start py-10 px-3 md:px-10 lg:py-0 lg:items-center min-h-screen">
      <div className="flex flex-col justify-center items-center">
        <AddAboutImage />
        <div className="flex flex-col justify-center items-center space-y-1 my-5 w-[800px] px-5 lg:p-10 md:space-y-3 border-2 rounded-md bg-white">
          <h1 className="text-xl py-2 md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2">
            Update About{" "}
            <FaPencilAlt className="inline-block text-teal-accent-400 mb-2" />
          </h1>

          <form
            onSubmit={handleFirstAboutUpdate}
            className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
          >
            <lable className="text-xl font-semibold pl-2 py-2">
              Update first part
            </lable>
            <textarea
              name="firstAbout"
              placeholder="First about"
              className="w-full h-20 md:h-32 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none"
            ></textarea>
            <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
              Update now
            </button>
          </form>

          <form
            onSubmit={handleMiddleAboutUpdate}
            className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
          >
            <lable className="text-xl font-semibold pl-2 py-2">
              Update middle part
            </lable>
            <textarea
              name="middleAbout"
              placeholder="Middle about"
              className="w-full h-20 md:h-32 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none"
            ></textarea>
            <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
              Update now
            </button>
          </form>

          <form
            onSubmit={handleLastAboutUpdate}
            className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
          >
            <lable className="text-xl font-semibold pl-2 py-2">
              Update last part
            </lable>
            <textarea
              name="lastAbout"
              placeholder="Last about"
              className="w-full h-20 md:h-32 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none"
            ></textarea>
            <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
              Update now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAbout;
