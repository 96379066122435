import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DoubleComponent from "../DoubleComponent/DoubleComponent";
import { UserContext } from "../../context/Context";
import SnearthNav from "./SnearthNav";
import AdminDoubleComponent from "../AdminPanel/AdminDoubleComponent/AdminDoubleComponent";
import HeroScetions from "../HeroSection/HeroScetions";

const Sneharth = () => {
  const { index, setIndex } = useContext(UserContext);
  const [medias, setMedias] = useState([]);
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/all-media")
      .then((res) => res.json())
      .then((data) => {
        setMedias(data);
      });
  }, [medias]);

  const handleIndex = (index) => {
    setIndex(index);
    console.log(medias);
  };
  //video

  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/video")
      .then((response) => response.json())
      .then((data) => setVideos(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="bg-black min-h-screen">
      <SnearthNav />
      <HeroScetions />

      <div className="px-5 md:px-16 lg:px-14 xl:px-20 py-5 md:py-10">
        {medias.length === 0 ? (
          <div className="flex justify-center items-center space-x-4 pt-1 mb-5">
            <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-white"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 mb-5 gap-3 max-h-[100vh] overflow-y-auto scrollbar-hide">
            {medias.map((media, index) => (
              <article class="rounded-lg  border-gray-300 my-3 lg:my-0 m-2   transition hover:shadow-lg ">
                <Link to={`/snearth/gallery/${media._id}`}>
                  <div className="flex flex-col lg:flex-row justify-start items-center lg:justify-between  m-0 px-0 md:px-2 lg:m-2 space-y-3 space-x-2 md:space-x-0 h-[420px] md:h-[580px] lg:h-[420px]  w-full">
                    <div className="w-full lg:w-2/3 object-cover h-[380px] md:w-[380px] md:h-[540px] lg:h-[400px] xl:h-[500px]">
                      <img
                        onClick={() => handleIndex(index)}
                        className="w-full h-[380px] md:h-[520px] lg:h-full object-cover"
                        src={media?.image}
                        alt=""
                      />
                    </div>
                    <p className="text-white text-base lg:text-[22px] leading-7 py-1 font-light text-center w-full lg:w-1/3">
                      {media.description}
                    </p>
                  </div>
                </Link>
              </article>
            ))}
          </div>
        )}
      </div>
      {/* video */}
      <div className="px-5 md:px-16 lg:px-14 xl:px-20 py-5 md:py-10">
        {medias.length === 0 ? (
          <div className="flex justify-center items-center space-x-4 pt-1 mb-5">
            <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-white"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 max-h-[100vh] overflow-y-auto scrollbar-hide">
            {videos.map((video, index) => (
              <article class="rounded-lg  border-gray-300  hover:scale-105 transition hover:shadow-lg ">
                <p className="text-xl md:texl-3xl font-semibold text-white pb-5">
                  VIDEOS
                </p>
                <div className="flex flex-col">
                  {video.youtube ? (
                    <iframe
                      src={video.youtube}
                      title="YouTube video player"
                      frameborder="0"
                      allowfullscreen
                      class="h-[270px]"
                    ></iframe>
                  ) : (
                    <video
                      className=" h-[280px] md:h-[250px] xl:h-[320px]"
                      src={video?.videoLink}
                      controls
                    />
                  )}
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
      <AdminDoubleComponent />
    </div>
  );
};

export default Sneharth;
