import React, { useContext, useEffect, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import { UserContext } from "../../../context/Context";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FaPenAlt } from "react-icons/fa";
import UpdateCaption from "./UpdateCaption";

const AdminSnearthModal = () => {
  const [medias, setMedias] = useState([]);
  const { index, setIndex } = useContext(UserContext);
  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/all-media")
      .then((res) => res.json())
      .then((data) => {
        setMedias(data);
      });
  }, []);
  console.log(index);
  const k = useLoaderData();

  const nextSlide = () => {
    setIndex(index + 1);
  };
  const prevSlide = () => {
    setIndex(index - 1);
  };

  const isFirstSlide = index === 0;
  const isLastSlide = index === medias.length - 1;

  return (
    <div className="flex flex-col justify-center items-center px-5 py-5 md:pt-10 md:px-10 min-h-screen bg-black">
      <div className="min-w-full md:min-w-[600px] lg:min-w-[900px] xl:min-w-[1100px]">
        <div className="flex justify-between py-5">
          <RxCrossCircled className=" text-black text-3xl" />

          <Link lassName="text-right " to="/AdminSnehaP/snearth">
            <RxCrossCircled className="text-white text-3xl" />
          </Link>
        </div>

        <div className="flex flex-col justify-center items-center w-full md:w-[680px] lg:min-w-[900px] xl:w-[1080px]">
          <div className="flex flex-col justify-center items-center w-full  relative">
            <img
              src={medias[index]?.image}
              className="w-[200px] h-[300px] md:w-[500px] md:h-[500px] lg:w-[770px] lg:h-[770px] xl:h-[800px] xl:w-[800px]  object-cover"
              alt=""
            />

            <FaArrowRight
              onClick={nextSlide}
              className={`absolute text-2xl right-0 ${
                isLastSlide
                  ? "text-black cursor-not-allowed"
                  : "text-white cursor-pointer"
              }`}
            />
            <FaArrowLeft
              onClick={prevSlide}
              className={`absolute text-2xl left-0 ${
                isFirstSlide
                  ? "text-black cursor-not-allowed"
                  : "text-white cursor-pointer"
              }`}
            />
          </div>
          <div className="flex items-center space-x-10">
            <p className="text-xl text-white font-semibold py-6">
              {medias[index]?.description}
            </p>
            <label htmlFor="mymodal">
              <FaPenAlt className="text-white text-3xl" />
            </label>
          </div>
          <UpdateCaption _id={medias[index]?._id} />
        </div>
      </div>
    </div>
  );
};

export default AdminSnearthModal;
