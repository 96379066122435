import React, { useEffect, useState } from "react";
import BlogCard from "../Blog/BlogCard";

const AllBlogModal = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    fetch("https://sneha-poddar-server-70f8de076d6f.herokuapp.com/all-blogs")
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
      });
  }, [blogs]);

  return (
    <div>
      <input type="checkbox" id="my-modal-3" className="modal-toggle" />
      <div className="modal">
        <div className=" modal-box w-11/12 max-w-[1200px]  relative">
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 xl:gap-10 max-h-[100vh] overflow-y-auto scrollbar-hide">
            {blogs.map((blog) => (
              <div className="bg-[#fff] border  w-[292px] lg:w-[250px] xl:w-[330px] rounded-md shadow-md ">
                <BlogCard blog={blog} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBlogModal;
